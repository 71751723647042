<div class="page">

  <div class="container">

    <div class="navbar pt-4">
      <a mat-button routerLink="/"
         class="home-link"
         routerLinkActive="active">
        <span class="t">T</span>
        <span class="four">4</span>
        <span class="f">F</span>
      </a>

      <div class="d-flex">
        <div class=" ">
          <a mat-button routerLink="/pricing" routerLinkActive="active">Pricing</a>
        </div>
      </div>
    </div>

    <div class="d-block text-center m-2">

      <h1>Translations for Figma</h1>
    </div>
  </div>
  <div class="middle-part" *ngIf="!inProgress">
    <div class="row justify-content-center m-2">

      <a mat-button
         color="accent"
         class="login-button"
         href="https://www.figma.com/oauth?client_id=juHRWNQfv5Pa1u2K6rRKVn&redirect_uri=https%3A%2F%2Ffigmat.com%2Flogin&scope=files%3Aread&state=figma&response_type=code">
        Login with Figma
        <svg class="svg" width="9" height="14" viewBox="0 0 38 57" xmlns="http://www.w3.org/2000/svg" alt="figma logo">
          <path d="M19 28.5c0-5.247 4.253-9.5 9.5-9.5 5.247 0 9.5 4.253 9.5 9.5 0 5.247-4.253 9.5-9.5 9.5-5.247 0-9.5-4.253-9.5-9.5z" fill-rule="nonzero" fill-opacity="1" fill="#1abcfe" stroke="none"></path>
          <path d="M0 47.5C0 42.253 4.253 38 9.5 38H19v9.5c0 5.247-4.253 9.5-9.5 9.5C4.253 57 0 52.747 0 47.5z" fill-rule="nonzero" fill-opacity="1" fill="#0acf83" stroke="none"></path>
          <path d="M19 0v19h9.5c5.247 0 9.5-4.253 9.5-9.5C38 4.253 33.747 0 28.5 0H19z" fill-rule="nonzero" fill-opacity="1" fill="#ff7262" stroke="none"></path>
          <path d="M0 9.5C0 14.747 4.253 19 9.5 19H19V0H9.5C4.253 0 0 4.253 0 9.5z" fill-rule="nonzero" fill-opacity="1" fill="#f24e1e" stroke="none"></path>
          <path d="M0 28.5C0 33.747 4.253 38 9.5 38H19V19H9.5C4.253 19 0 23.253 0 28.5z" fill-rule="nonzero" fill-opacity="1" fill="#a259ff" stroke="none"></path>
        </svg>
      </a>
      <a mat-button
         disabled="true"
         color="accent"
         href="https://accounts.google.com/o/oauth2/v2/auth?client_id=715514670668-mvdkeaggh1p914msiadh8562p28t15fo.apps.googleusercontent.com&redirect_uri=https%3A//figmat.com/login&response_type=code&scope=openid%20email&state=google"
         class="login-button">
        Login with Google
        <svg class="svg" width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" alt="google logo">
          <path d="M17.64 9.2c0-.637-.057-1.251-.164-1.84H9v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z" fill-rule="evenodd" fill-opacity="1" fill="#4285f4" stroke="none"></path>
          <path d="M9.003 18c2.43 0 4.467-.806 5.956-2.18L12.05 13.56c-.806.54-1.836.86-3.047.86-2.344 0-4.328-1.584-5.036-3.711H.96v2.332C2.44 15.983 5.485 18 9.003 18z" fill-rule="evenodd" fill-opacity="1" fill="#34a853" stroke="none"></path>
          <path d="M3.964 10.712c-.18-.54-.282-1.117-.282-1.71 0-.593.102-1.17.282-1.71V4.96H.957C.347 6.175 0 7.55 0 9.002c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill-rule="evenodd" fill-opacity="1" fill="#fbbc05" stroke="none"></path>
          <path d="M9.003 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.464.891 11.428 0 9.002 0 5.485 0 2.44 2.017.96 4.958L3.967 7.29c.708-2.127 2.692-3.71 5.036-3.71z" fill-rule="evenodd" fill-opacity="1" fill="#ea4335" stroke="none"></path>
        </svg>
      </a>

    </div>


    <div class="row justify-content-center m-2">
      or Log in with your company's SSO
    </div>
    <div class="d-flex justify-content-center">
      <form class="sso-email-form">
        <mat-form-field class="sso-email-field"
                        [hintLabel]="hint">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"

                 placeholder="Ex. example@example.com">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="d-flex justify-content-center">
      <button disabled
        mat-raised-button color="primary"
        class="sso-login-button">
        Log in
      </button>
    </div>
  </div>


</div>
