<div class="privacy-container">
ABOUT
<br>
Figmat, LLP develops tools for web-designers and adjacent developers which help them to manage internationalization workflow in developing process. As such, we provide web-application and a plugin for "Figma" which allows to enter all data that should be translated. This Privacy Policy is designed to help you understand how we collect, use and share your personal information and to assist you in exercising the privacy rights available to you.
<br>
<br>
SCOPE
<br>
This Privacy Policy applies to personal information processed by us in our business, including on our website (figmat.com) and our plugin "Translations for Figma".
<br>
This Privacy Policy does not apply to any third-party websites, services or applications, even if they are accessible through our Services. In addition, capitalized terms not defined in this Privacy Policy will have the meaning set forth in our Terms of Service.
<br>
Please note that if you use the Services on behalf of an organization (e.g., your employer), Figmat’s use of your personal information may be governed by the agreement we have with that organization. However, we will still process your information consistent with this Privacy Policy.
<br>
<br>
PERSONAL INFORMATION WE COLLECT
<br>
The categories of personal information we collect depend on how you interact with our Services.
<br>
Information You Provide to Us<br>
Account Information. Figmat uses 3rd-party services to authorize users. When you connect an Account, we will collect personal information, such as your name, email address, personal website, and picture.
<br>
Payment Information. We may sell services or merchandise through our Services. When you make purchases through the Services, we may process your payments through a third-party application, such as the Apple App Store, Google Play App Store, Amazon App Store, social networking sites such as Facebook, and/or payment processing services such as Stripe. The third-party application may collect certain financial information from you to process a payment on behalf of Figma, including your name, email address, address, payment card information, and other billing information. Figma does not store or receive your payment information, but it may store and receive information associated with your payment information (e.g., your billing details).
<br>
Your Communications with Us. We collect personal information from you such as email address, phone number, or mailing address when you request information about our Services, register for our newsletter, or otherwise communicate with us.
<br>
Surveys. We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information, which may include personal information.
<br>
Customer Service, Training and Quality Assurance. If you call or otherwise interact with Figmat’s customer service and support, we may collect the information you provide to our representatives. In addition, we may record telephone calls or video conferences between you and our representatives for training or quality assurance purposes.
<br>
Registration for Sweepstakes or Contests. We may run sweepstakes and contests. Contact information you provide may be used to reach you about the sweepstakes or contest and for other promotional, marketing and business purposes, if permitted by law. In some jurisdictions, we are required to publicly share information about the winners.
<br>
Business Development and Strategic Partnerships. We may collect personal information from individuals and third parties to assess and pursue potential business opportunities.
<br><br>
Information Collected Automatically<br>
Automatic Data Collection. We may collect certain information automatically when you use the Services. This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile advertising and other unique identifiers, details about your browser, operating system or device, location information (including inferred location based off of your IP address), Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, and information about how you interact with and use the Services.
<br>
We may use a visitor’s IP address to ﬁght spam, malware and identity theft. With your permission, we may also collect information about your operating system’s installed fonts in connection with providing the Services to you. Combined with other system information, this information could be used to give your computer a unique ﬁngerprint/signature and enable us to track your usage of our Services after you log out.
<br>
Cookies, Pixel Tags/Web Beacons, and Analytics Information. We, as well as third parties that may provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through the Services. Technologies are essentially small data files placed on your device that allow us and our partners to record certain pieces of information whenever you visit or interact with our Services.
<br>
Cookies. Cookies are small text files placed in device browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.
Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects information about engagement on the Services. The use of a pixel tag allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.
Analytics. We may also use Google Analytics and other service providers to collect and process analytics information on our Services. For more information about how Google uses data, please visit www.google.com/policies/privacy/partners/. You can opt out of Google Analytics’ collection and processing of data generated by your use of our website by going to http://tools.google.com/dlpage/gaoptout.
<br><br>
GOOGLE USER's DATA<br>
For those users who decided to share their Google data inorder to get access to the service, the application keeps email and openid to identify the users and record the usage of the service. To get details on how the data is used see HOW WE USE YOUR INFORMATION.
<br><br>
HOW WE USE YOUR INFORMATION<br>
We use your personal information for a variety of business purposes, including:
<br>
To Provide the Services or Information Requested, such as:
<br>
<ul>
  <li>Fulfilling our contract with you or the organization on whose behalf you use the Services;</li>
  <li>Managing your information and Account;</li>
  <li>Responding to questions, comments, and other requests;</li>
  <li>Processing payment card and/or other financial information to facilitate your use of the Services;</li>
  <li>Providing access to certain areas, functionalities, and features of our Services;</li>
  <li>Answering requests for customer or technical support; and</li>
  <li>Allowing you to register for events.</li>
  <li>Administrative Purposes, such as:</li>
</ul>
<br>
Pursuing legitimate interests, such as direct marketing, research and development (including marketing research), network and information security, and fraud prevention;<br>
Measuring interest and engagement in our Services;<br>
Improving the Services;<br>
Developing new products and services;<br>
Ensuring internal quality control and safety;<br>
Authenticating and verifying individual identities;<br>
Carrying out audits;<br>
Communicating with you about your account, activities on our Services and Privacy Policy changes;<br>
Preventing and prosecuting potentially prohibited or illegal activities;<br>
Enforcing our agreements; and<br>
Complying with our legal obligations.<br>
Marketing Our Products and Services. We may use personal information to tailor and provide you with content and advertisements. We may provide you with these materials as permitted by applicable law
<br>
If you have any questions about our marketing practices or if you would like to opt out of the use of your personal information for marketing purposes, you may contact us at any time as set forth below.
<br>
Consent. We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent.
<br>
De-identified and Aggregated Information Use. We may use personal information and other data about you to create de-identified and/or aggregated information, such as de-identified demographic information, de-identified location information, information about the device from which you access our Services, or other analyses we create. De-identified and/or aggregated information is not personal information, and we may use and disclose such information in a number of ways, including research, industry analysis, analytics, and any other legally permissible purposes.
<br>
Share Content with Friends or Colleagues. Our Services may offer various tools and functionalities that allow you to share content. For example, we may allow you to provide information about your friends through our referral services. Our referral services may allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use our Services.
<br>
How We Use Automatic Collection Technologies. We, as well as third parties that may provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies to automatically collect information through the Services. Our uses of these Technologies fall into the following general categories:
<br>
Operationally Necessary. This includes Technologies that allow you access to our Services, applications, and tools that are required to identify irregular site behavior, prevent fraudulent activity and improve security or that allow you to make use of our functionality;<br>
Performance Related. We may use Technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how our visitors use the Services;<br>
Functionality Related. We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed;<br>
Advertising or Targeting Related. We may use first party or third-party Technologies to deliver content, including ads relevant to your interests, on our Services or on third party sites.<br>
Please note that this Privacy Policy covers only our use of Technologies and does not govern the use of Technologies by any third parties.<br>
<br>
Cross-Device Tracking. Your browsing activity may be tracked across different websites and different devices or apps. For example, we may attempt to match your browsing activity on your mobile device with your browsing activity on your laptop. To do this our technology partners may share data, such as your browsing patterns, geo-location and device identifiers, and we may match the information of the browser and devices that appear to be used by the same person.
<br><br>
DISCLOSING YOUR INFORMATION TO THIRD PARTIES<br>
We may share any personal information we collect with the following categories of third parties:<br>

For Collaboration. You can create content, which may contain information about you, and grant permission to others to see, share, edit, copy and download that content based on settings you or your administrator (if applicable) select. Some of the collaboration features of the Services display some or all of your profile information to other Service users when you share or interact with specific content. For example, when you comment, we display your profile picture and name next to your comments so that other users with access to the comment understand who made it. Similarly, when you join a team, your name, profile picture and contact information will be displayed in a list for other team members so they can find and interact with you. Please be aware that some content can be published or otherwise made publicly available, including information about you, can be publicly viewed and indexed by and returned in search results of search engines. You can check the settings at any time to confirm whether particular content is public or private. In addition, in connection with your posting of content, we will share your picture, full legal name, user handle, and Twitter (or other social networking site) handle (to the extent you have provided us with this information). We are not responsible for privacy practices of the other users who may view and use the posted information.<br>
Managed Accounts and Administrators. If you register or access the Services using an email address with a domain that is owned by your employer or organization, or associate that email address with your existing account and such organization wishes to establish a services account, certain information about you including your name, profile picture, contact info, content, and account use may become accessible to that organization’s administrator and other service users, as permitted by your administrator, to provide you additional products and services or to integrate with Figmat or other products and services. For example, your organization may request that we provide extra security controls around your account to protect information about your organization or your organization may request that we link your Figmat account with your organization’s account to enhance collaboration and functionality among tools you use. If you are the administrator of a team, organization or other account holder within the Services, we may share your contact information with current or past Service users, for the purpose of facilitating Service-related requests. Please note that your information may also be subject to your organization’s privacy policy. We are not responsible for the privacy or security practices of our customers.<br>
Service Providers. We may share any personal information we collect about you with our third-party service providers. The categories of service providers to whom we entrust personal information include service providers for: (i) the provision of the Services; (ii) the provision of information, products, and other services you have requested; (iii) marketing and advertising; (iv) payment and transaction processing; (v) customer service activities; and (vi) the provision of IT and related services.<br>
<br>
Third-Party Platforms and Services. We will share your personal information with third-party platforms and/or services if you have expressly consented or requested that we do so. Please note we do not endorse, screen or approve, and are not responsible for, the practices or conduct of such third-party services.<br>
<br>
Business Partners. We may provide personal information to business partners to provide you with a product or service you have requested. We may also provide personal information to business partners with whom we jointly offer products or services.<br>
<br>
Affiliates. We may share personal information with our affiliated entities.<br>
<br>
Advertising Partners. Through our Services, we may allow third party advertising partners to set Technologies and other tracking tools to collect information regarding your activities and your device (e.g., your IP address, cookie identifiers, page(s) visited, location, time of day). These advertising partners may use this information (and similar information collected from other websites) for purposes of delivering targeted advertisements to you when you visit third party services within their networks. This practice is commonly referred to as “interest-based advertising” or “personalized advertising.” If you prefer not to share your personal information with third party advertising partners, you may follow the instructions below.<br>
<br>
Disclosures to Protect Us or Others. We may access, preserve, and disclose any information we have associated with you if we believe doing so is required or appropriate to: (i) comply with law enforcement or national security requests and legal process, such as a court order or subpoena; (ii) protect your, our or others’ rights, property, or safety; (iii) to enforce Figmat’s policies and contracts; (iv) to collect amounts owed to us; (v) when we believe disclosure is necessary or appropriate to prevent financial loss or in connection with an investigation or prosecution of suspected or actual illegal activity; or (vi) if we, in good faith, believe that disclosure is otherwise necessary or advisable.<br>
<br>
Disclosure in the Event of Merger, Sale, or Other Asset Transfer. If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.<br>
<br>
INTERNATIONAL DATA TRANSFERS<br>
All information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable laws.<br>
<br>
YOUR CHOICES<br>
General. You may have the right to opt out of certain uses of your personal information.
<br><br>
Email. If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails regarding products or Services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding the Services or updates to our Terms of Service or this Privacy Policy).
<br><br>
“Do Not Track”. Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.
<br><br>
Cookies and Interest-Based Advertising. You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits. Please note that cookie-based opt-outs are not effective on mobile applications. However, you may opt-out of personalized advertisements on some mobile applications by following the instructions for Android and iOS.
<br><br>
Please note you must separately opt out in each browser and on each device.
<br><br>
YOUR PRIVACY RIGHTS<br>
In respect of your rights, we do not “sell” your personal information.
<br><br>
In accordance with applicable law, you may have the right to: (i) request confirmation of whether we are processing your personal information; (ii) obtain access to or a copy of your personal information; (iii) receive an electronic copy of personal information that you have provided to us, or ask us to send that information to another company (the “right of data portability”); (iv) object to or restrict our uses of your personal information; (v) seek correction or amendment of inaccurate, untrue, incomplete, or improperly processed personal information; (vi) withdraw your consent and (vii) request erasure of personal information held about you by us, subject to certain exceptions prescribed by law. If you would like to exercise any of these rights, please contact us as set forth below.
<br><br>
We will process such requests in accordance with applicable laws. To protect your privacy, we will take steps to verify your identity before fulfilling your request.
<br><br>
Please note that if you use our Services on behalf of an organization (e.g., your employer), that organization may be responsible for fulfilling the individual rights requests referenced above.
<br><br>
DATA RETENTION<br>
We store the personal information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.
<br><br>
SECURITY OF YOUR INFORMATION<br>
We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable laws, we do not accept liability for unauthorized disclosure.
<br>
<br id="vulnerability-management">
Vulnerability Management Process<br>
Our vulnerability management process includes regular internal security testing, encouraging user feedback, and staying informed about security advisories. Reported vulnerabilities are assessed and prioritized based on their severity. We develop and test fixes, release updates promptly, and communicate security changes to our users. We continuously monitor for new vulnerabilities and strive to maintain a secure plugin environment.<br>
Vulnerability Identification:
<ul>
  <li>Internal testing: We regularly conduct security testing on your code, including code reviews, static analysis, and dynamic testing.</li>
  <li>External feedback: We encourage users to report potential vulnerabilities through a dedicated email address with potential reward that includes free subscribtion on one of plan.</li>
</ul>
Vulnerability Assessment and Security releases frameword<br>
<ul>
  <li>Promtly analyze reported vulnerabilities to determine their severity and potential impact</li>
  <li>Prioritize vulnerabilities based on their risk level</li>
  <li>Release the updated plugin version through the Figma plugin marketplace, backend services updated through internal automatic CI/CD system</li>
  <li>If necessary, directly notify users about critical vulnerabilities and urge them to update</li>
</ul>
<br><br>
By using the Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Services, by mail or by sending an email to you.
<br><br>
THIRD PARTY WEBSITES/APPLICATIONS<br>
The Services may contain links to other websites/applications and other websites/applications may reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve, and are not responsible for, the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.
<br><br>
CHILDREN’S INFORMATION<br>
The Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect personal information from children. If you learn that your child has provided us with personal information without your consent, you may contact us as set forth below. If we learn that we have collected a child’s personal information in violation of applicable law, we will promptly take steps to delete such information and terminate the child’s account.
<br><br>
SUPERVISORY AUTHORITY<br>
If you are located in the European Economic Area or the United Kingdom, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.
<br><br>
CHANGES TO OUR PRIVACY POLICY<br>
We may revise this Privacy Policy from time to time at our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use the Services after the new Privacy Policy takes effect.
<br><br>
CONTACT US<br>
If you have any questions about our privacy practices or this Privacy Policy, or if you wish to submit a request to exercise your rights as detailed in this Privacy Policy, please contact us at:
<br><br>
Contact@figmat.com
<br><br>
</div>